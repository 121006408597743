/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    strong: "strong",
    ul: "ul",
    li: "li",
    br: "br"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, LandingPageCta, SideBySide, StateCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!StateCta) _missingMdxReference("StateCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-aids/types/cros-bicros/'], ['en', 'https://www.hear.com/hearing-aids/types/cros-bicros/'], ['en-US', 'https://www.hear.com/hearing-aids/types/cros-bicros/'], ['en-CA', 'https://ca.hear.com/hearing-aids/types/cros-bicros/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "hearing-loss-in-one-ear",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-loss-in-one-ear",
    "aria-label": "hearing loss in one ear permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Loss in One Ear"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You may not realize it, but for many people, hearing loss isn’t always experienced equally in both ears. In the U.S. alone, hearing loss in one ear affects 60,000 people every year. 1 in 1,000 children are born with unilateral hearing loss, and 3% of school-age children have UHL. This type of single-sided deafness is also known as unilateral hearing loss (UHL) People who live with unilateral hearing loss face some unique challenges. Fortunately, with cutting-edge technology, there are a number of ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aid"), " options now available that are specifically designed for unilateral hearing loss. This will allow you to continue to living a full and active life."), "\n", React.createElement(LandingPageCta, {
    copy: "Try Cros/BiCros hearing aids",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-is-unilateral-hearing-loss-and-how-to-manage-it",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-unilateral-hearing-loss-and-how-to-manage-it",
    "aria-label": "what is unilateral hearing loss and how to manage it permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What Is Unilateral Hearing Loss and How to Manage It"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Unilateral hearing loss is a condition in which hearing functions normally in one ear while the other ear experiences significant hearing loss"), ". There are quite a few issues that can arise when you are experiencing UHL. For instance, a you might have trouble following conversations in environments with lots of background noise, like public events or a dinner party."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You might also have trouble with sound localization (being able to tell exactly where a sound is coming from), tinnitus, and problems detecting sound from the side of the head that has hearing loss. If you live with UHL, you might often struggle in certain everyday situations and experience increased stress and anxiety. These issues occur because unilateral hearing loss interferes with what is known as directional hearing."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-is-directional-hearing",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-directional-hearing",
    "aria-label": "what is directional hearing permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What Is Directional Hearing?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Directional hearing is the ability to locate the origin of certain sounds"), " – this is made possible by binaural hearing, or healthy hearing with both ears. Binaural hearing helps you to localize the source of sounds and filter out background noise. When you live with unilateral hearing loss, you might experience something known as “the head shadow effect”, where sounds that originate from the side of the head with hearing loss are not processed by the brain."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This is especially true ", React.createElement(_components.a, {
    href: "/hearing-loss/high-frequency/",
    className: "c-md-a"
  }, "of sounds that occur in the high-frequency range"), ". Consonant sounds, compared to vowel sounds, are delivered in the high-frequency range, and so large amounts of a conversation can be missed if you have unilateral hearing loss. Without directional hearing, it can be extremely difficult to understand speech and successfully communicate in situations with lots of ambient noise."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "causes-of-unilateral-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#causes-of-unilateral-hearing-loss",
    "aria-label": "causes of unilateral hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Causes of Unilateral Hearing Loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Experts have determined many causes of unilateral hearing loss including:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, React.createElement(_components.a, {
    href: "/resources/hearing-loss/inherited-hearing-loss/",
    className: "c-md-a"
  }, "Congenital hearing loss")), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Acoustic neuroma, a benign tumor growing on the auditory nerve (", React.createElement(_components.a, {
    href: "/resources/hearing-loss/acoustic-neuroma/",
    className: "c-md-a"
  }, "more info here"), ")"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Some viral infections"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Traumatic brain injuries"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Disorders such as ", React.createElement(_components.a, {
    href: "/hearing-loss/meniere-disease/",
    className: "c-md-a"
  }, "Meniere’s Disease")), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Childhood measles or mumps"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Despite the multiple causes of unilateral hearing loss, there are a variety of solutions that can help you manage to live with Unilateral Hearing Loss (UHL)."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "hearing-aids-for-unilateral-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#hearing-aids-for-unilateral-hearing-loss",
    "aria-label": "hearing aids for unilateral hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Hearing Aids for Unilateral Hearing Loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are a variety of innovative devices ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/",
    className: "c-md-a"
  }, "from leading hearing aid manufacturers"), " that are designed specifically to help with unilateral hearing loss. For those with contralateral hearing (normal hearing in the good ear) and only mild hearing loss in the other ear, a traditional hearing aid is a possibility. If you experience normal contralateral hearing and severe hearing loss in the other ear, there are devices that take the sound from the impaired side and send it to the side with normal hearing. This is known as a Contralateral Routing of Signal, or CROS system. There are 2 hearing aid systems that use CROS technology, which are known as CROS and BiCROS hearing aids."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-are-cros-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-are-cros-hearing-aids",
    "aria-label": "what are cros hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What Are CROS Hearing Aids?"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/cros.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "CROS hearing aids consist of two in-the-ear devices"), ": a microphone worn in the ear with hearing loss and a receiver that is worn in the functioning ear. The microphone picks up sound from the side with no hearing and sends it to the receiver in the functional ear. CROS hearing aids work by replicating the experience of standard binaural hearing, making it easier to understand speech in complex noise environments and pinpoint the location of a sound. There are wired CROS systems, but many devices now use ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/",
    className: "c-md-a"
  }, "advanced wireless technology"), " and function using radio signals. Hearing aid manufacturers make ", React.createElement(_components.a, {
    href: "/hearing-aids/types/ite/",
    className: "c-md-a"
  }, "in-the-ear devices"), " and ", React.createElement(_components.a, {
    href: "/hearing-aids/types/bte/",
    className: "c-md-a"
  }, "behind-the-ear"), " devices that are both discreet and comfortable. There are also CROS systems that function through bone conduction, where a device is surgically implanted and functions by sending vibrations through the bones in the ear canal to the ear with hearing loss. Another device option for treating UHL is a cochlear implant.")), "\n", React.createElement(LandingPageCta, {
    copy: "Try these risk-free",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-are-bicros-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-are-bicros-hearing-aids",
    "aria-label": "what are bicros hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What Are BiCROS Hearing Aids?"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/bi-cros.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "BiCROS hearing aids (bilateral microphones with contralateral routing of signal) are designed for people who have bilateral hearing loss"), ", or hearing loss in both ears. A BiCROS system consists of a microphone that picks up sounds from the ear with little to no hearing and a receiver in the ear with better hearing. The ear with better hearing processes the sounds sent there by the microphone. A traditional hearing aid in the ear with normal hearing amplifies the sounds that are sent there from the side with no hearing.")), "\n", React.createElement(LandingPageCta, {
    copy: "Try these risk-free",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "cros-vs-bicros-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cros-vs-bicros-hearing-aids",
    "aria-label": "cros vs bicros hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "CROS vs. BiCROS Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The difference between CROS and BiCROS hearing aids is that the CROS system is designed for people who have no hearing in one ear and close to perfect hearing in the other ear; the BiCROS system is designed for people who have total hearing loss in one ear and moderate hearing loss in the other ear. In a BiCROS system, the microphone on the side with no hearing picks up sound and sends it to the side with some hearing, where the traditional hearing aid amplifies the sound received from the other side. A CROS device does not incorporate any amplification of the sound picked up by the microphone."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "cros-and-bicros-hearing-aids-from-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cros-and-bicros-hearing-aids-from-hearcom",
    "aria-label": "cros and bicros hearing aids from hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "CROS and BiCROS Hearing Aids From hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you are feeling overwhelmed by the options for treating UHL, ", React.createElement(_components.a, {
    href: "/",
    className: "c-md-a"
  }, "hear.com"), " can help find a CROS or BiCROS hearing aid that works for you. Simply fill out the questionnaire to speak with one of our experts on the phone. After that, you will be connected with a local Partner Provider in your area ", React.createElement(_components.a, {
    href: "/hearing-test/",
    className: "c-md-a"
  }, "to take a hearing test"), " and have your hearing aids fitted. You can then try out your new hearing aids for 45 days risk-free and experience the benefits for yourself!"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "examples-of-cros-and-bicros-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#examples-of-cros-and-bicros-hearing-aids",
    "aria-label": "examples of cros and bicros hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Examples of CROS and BiCROS Hearing Aids"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/horizon-mini-ix-hearing-aids.png"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "horizon-mini-ix",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#horizon-mini-ix",
    "aria-label": "horizon mini ix permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Horizon Mini IX"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Horizon Mini IX hearing aids are ultra discreet, extremely powerful, and provide the best natural sound to keep you on the go and hearing life the way it was meant to be heard.  It is a completely-in-canal (CIC) hearing aid, which means it disappears inside your ear. No one will ever know you’re wearing it.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/mini-ix/",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/horizon-go-ix-hearing-aids.png"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "horizon-go-ix",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#horizon-go-ix",
    "aria-label": "horizon go ix permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Horizon Go IX"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Engineered for active people who desire world-class hearing that lasts all day, Horizon Go IX rises to the top of its class with an extended battery life that only stops when you do. With seamless connectivity and no tiny batteries to change, staying connected while on the go has never been so easy.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", React.createElement(_components.a, {
    href: "/hearing-aids/horizon/go-ix/",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-phonak.png"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "phonak-cros-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#phonak-cros-hearing-aids",
    "aria-label": "phonak cros hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Phonak CROS hearing aids"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Your world is constantly changing, so why shouldn’t your hearing aids adapt with it? Thanks to the latest designs and technology from Phonak, you can head out into the world ready to take on any challenge. Learn more about the history of this great company and nerd out on some of their latest innovations, so you can find the hearing aid that’s right for your needs.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", React.createElement(_components.a, {
    href: "/hearing-aids/brands/phonak/",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/brands-widex.png"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "widex-cros-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#widex-cros-hearing-aids",
    "aria-label": "widex cros hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Widex CROS hearing aids"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Christian Topholm and Erik Westermann founded Widex in 1956 in a Denmark basement. The first Widex hearing aid fit in a pocket. In 1988, the company introduced its first digitally programmable hearing aid. The first digital in-the-ear hearing aid came out in 1995.\n", React.createElement(_components.br), "\n", React.createElement(_components.br), "\n", React.createElement(_components.a, {
    href: "/hearing-aids/brands/widex/",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "contact-a-hearing-specialist-from-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#contact-a-hearing-specialist-from-hearcom",
    "aria-label": "contact a hearing specialist from hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Contact a hearing specialist from hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we’re dedicated to making your journey to better hearing easy and fast. Our team of hearing specialists, along with the top 2% of audiologists in the United States, are here to guide you through the entire process. From initial consultation to fitting and beyond, we ensure you receive personalized care and the best hearing solutions available."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Take the first step towards enhanced hearing today. Contact hear.com to schedule your consultation and experience the difference that expert guidance and advanced technology can make. Your path to better hearing starts now with hear.com."), "\n", React.createElement(StateCta, {
    backgroundColor: "white"
  }), "\n", React.createElement(LandingPageCta, {
    copy: "Try Cros-Bicros hearing aids",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
